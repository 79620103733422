<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-11">
      <div class="card full-height bg-ligth">
        <!-- <div class="card-body"> -->
        <div class="row card-body form-group">
          <div class="form-group col-md-3">
            <label class="col-md-12">{{ $t("DATAINICIO.NOME") }}</label>
            <input required type="date" class="form-control" v-model="filtro.data_inicio" />
          </div>
          <div class="form-group col-md-3">
            <label class="col-md-12">{{ $t("DATAFINAL.NOME") }}</label>
            <input required type="date" class="form-control" v-model="filtro.data_fim" />
          </div>
          <div class="form-group col-md-3">
            <label>Tipo</label>
            <treeselect v-model="filtro.tipo_id" :multiple="false" :options="lista_tipos_produto" />
          </div>
          <div class="form-group col-md-3">
            <label class="col-md-12">Tipo de saida :</label>
            <treeselect v-model="filtro.tipo_saida_id" :multiple="false" :options="options" />
          </div>

          <div class="form-group col-md-3">
            <label class="col-md-12">Filial :</label>
            <treeselect v-model="filtro.filial_id" :multiple="false" :options="lista_filials" />
          </div>
          <div class="form-group col-md-3">
            <label class="col-md-12">Substancia :</label>
            <treeselect v-model="filtro.substancia_id" :multiple="false" :options="lista_substancias" />
          </div>
          <div class="form-group col-md-3">
            <label class="col-md-12">Seleccionar el producto :</label>
            <treeselect v-model="filtro.produto_id" :multiple="false" :options="lista_produtos" />
          </div>
          <div class="form-group col-md-3">
            <label class="col-md-12">Lote :</label>

            <b-input-group class="mb-2 mr-sm-2 mb-sm-0 bg-secondary">
              <input required type="text" class="form-control" v-model="nome_pesquisa" @click="pesquisar()" />
              <b-button v-b-modal.SelecionarLote class="btn btn-indo">
                <i class="fa fa-search search-icon m-0 p-0"></i>
              </b-button>
            </b-input-group>

            <div>
              <b-modal id="SelecionarLote" hide-footer hide-header title="SelecionarLote" ref="modal_lote">
                <input required type="text" class="form-control" v-model="nome_pesquisa" />
                <b-table :fields="['lote', 'produto', 'validade', 'acoes']" :items="lista_estoque_produtos"
                  :per-page="perPage" :current-page="currentPage" id="atendimento-table" class="table table-hover"
                  show-empty empty-text="Nenhum registro encontrado!">
                  <template #cell(acoes)="{ item }">
                    <button @click="selecionarEstoque(item)" class="btn btn-light mx-1" v-b-tooltip.hover
                      title="Clique para selecionar paciente">
                      Selecionar
                    </button>
                  </template>
                </b-table>
                <b-pagination v-model="currentPage" :total-rows="lista_estoque_produtos.length" :per-page="perPage"
                  aria-controls="atendimento-table">
                </b-pagination>
              </b-modal>
            </div>
          </div>

          <div class="col-md-3 justify-content-end row mt-8">
            <div class="ml-1">
              <button class="btn btn-success" @click="listar_saida_produtos()">
                <i class="fa fa-search search-icon m-0 p-0"></i>
              </button>
            </div>
            <div class="ml-1">
              <!-- <button class="btn btn-info" @click="gerarExcel()">
                <i class="fa fa-print m-0 p-0"></i>
              </button> -->
              <b-dropdown id="dropdown-1" variant="info" text="Imprimir">
                <b-dropdown-item v-b-tooltip.hover @click="gerarExcel()"
                  title="genera un excel conforme lo que esta selecionado en los campos">Excel Salidas</b-dropdown-item>
                <b-dropdown-item v-b-tooltip.hover @click="gerarExcelFilial()"
                  title="genera un excel contabilizando las salidas de cada clinica. Se desconsidera el filtro de filial">Excel
                  Salidas por Clínica</b-dropdown-item>
              </b-dropdown>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-11 pt-3">
      <div class="card full-height bg-ligth">
        <!-- <div class="card-header">
          <h3 class="mb-0">Salidas de Productos General</h3>
        </div> -->
        <div class="card-body">
          <b-table :fields="[
            'produto',
            'codigo_barra',
            'descricao',
            'lote',
            'tipo',
            'paciente',
            'quantidade',
            'valor_fracionario',
            'valor_gasto',
            'acoes',
          ]" :items="lista_saida_produtos" :per-page="perPage" :current-page="currentPage" id="saida_produto" class="
                table
                table-responsive
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
              " show-empty empty-text="Nenhum registro encontrado!">
            <template #head(acoes)><span></span></template>
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <button v-show="lista_permissoes_filial.u_Sala" @click="atualizar(item)"
                  class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Editar registro">
                  <i class="far fa-edit text-primary"></i>
                </button>

                <button v-show="lista_permissoes_filial.lock_Sala" @click="confirm('desfazer a saída', item)"
                  class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Desfazer saida">
                  <i class="fas fa-unlock-alt text-danger"></i>
                </button>
              </div>
            </template>
            <template #cell(tipo)="{ item }"><span></span>
              <span style="font-size: 12px" class="label label-lg label-inline" :class="{
                'label-light-success': item.tipo === 1,
                'label-light-info': item.tipo === 2,
                'label-light-warning': item.tipo === 3,
                'label-light-primary': item.tipo === 4,
              }">
                {{
                  item.tipo === 1
                  ? "Paciente"
                  : item.tipo === 2
                    ? "Vencido"
                    : item.tipo === 3
                      ? "Danificado"
                      : item.tipo === 4
                        ? "Donacion"
                        : item.tipo === 5
                          ? "Estoque Retroativo "
                          : "Saida unica"
                }}
              </span>
            </template>
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="lista_saida_produtos.length" :per-page="perPage"
            aria-controls="saida_produto">
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
export default {
  mixins: { fireAlert },
  data() {
    return {
      currentPage: 2,
      perPage: 10,
      filtro: {
        data_inicio: null,
        data_fim: null,
        produto_id: null,
        tipo_saida_id: null,
        tipo_id:null,
        estoque_produto_id: null,
        filial_id: null,
        tipo_relatorio: "geral",
        substancia_id: null
      },
      nome_pesquisa: null,

      options: [
        {
          id: 1,
          label: "Paciente",
          isNew: true,
        },
        {
          id: 2,
          label: "Vencido",
        },
        {
          id: 3,
          label: "Danificado",
        },
        {
          id: 4,
          label: "Donación",
        },
        {
          id: 5,
          label: "Estoque Retroativo",
        },
        {
          id: 6,
          label: "Saida Única",
        },
      ],
    };
  },
  watch: {
    nome_pesquisa() {
      if (this.nome_pesquisa.length >= 3) {
        var p = {
          texto: this.nome_pesquisa,
          tipo: "geral"
        };
        this.$store.dispatch("produto/pesquisar_estoque_por_lotes", p);
      }
      if (this.nome_pesquisa.length == 0) this.filtro.estoque_produto_id = null;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Salida de Producto" }]);
  },
  created() {
    // this.listar_saida_produtos();
    this.listar_requerimentos();
  },
  computed: {
    lista_saida_produtos() {
      return this.$store.state.produto.lista_saida_produtos;
    },
    lista_tipos_classe() {
      return this.$store.state.tipoClasse.lista_tipos_classe;
    },
    lista_substancias() {
      return this.$store.state.substancia.lista_substancias;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.produto.mensagem_alert;
    },
    lista_produtos() {
      return this.$store.state.produto.lista_produtos;
    },
    lista_tipos_produto() {
      return this.$store.state.produto.lista_tipos_produto;
    },
    lista_estoque_produtos() {
      return this.$store.state.produto.lista_estoque_produtos;
    },
    lista_filials() {
      return this.$store.state.configEmpresa.lista_filials;
    },
  },
  methods: {
    async listar_saida_produtos() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch("produto/listar_saida_produtos", this.filtro)
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
          this.$store.dispatch("produto/listar_produto", "");

          // localStorage.removeItem('produto');
        });
    },
    async listar_requerimentos() {
      this.filtro.filial_id = window.localStorage.getItem('ucpFilialId')
      await this.$store.dispatch("configEmpresa/listar_filial_empresa");
      await this.$store.dispatch("produto/listar_produtos");
      await this.$store.dispatch("substancia/listar_substancias");
      await this.$store.dispatch("tipoClasse/listar_tipos_classe");
      await this.$store.dispatch("produto/listar_tipos_produto")
    },
    pesquisar() {
      this.$store.dispatch(
        "produto/pesquisar_estoque_por_lotes",
        this.nome_pesquisa
      );
    },
    async gerarExcel() {
      await this.$store.dispatch("relatorios/excelSaidas", this.filtro);

    },
    async gerarExcelFilial() {
      await this.$store.dispatch("relatorios/excel_saidas_empresa", this.filtro);

    },
    selecionarEstoque(item) {
      this.filtro.estoque_produto_id = item.id;
      this.filtro.produto_id = item.produto_id;
      this.$refs["modal_lote"].hide();
    },
    atualizar(value) {
      this.$router.push({
        name: "createSaidaProduto",
        params: { saida_produto_id: value.id },
      });
      // this.$store.dispatch("configEmpresa/atualizar", value);
      // localStorage.setItem('produto',JSON.stringify(value));
      // this.$store.dispatch("produto/listar_produto", value.id);
    },
    async confirm(value, item) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação ` + value,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (value == "desfazer a saída") this.desfazer(item);
          }
        },
      });
    },
    async desfazer(item) {
      await this.$store.dispatch("produto/desfazer_saida_produto", item.id);
      this.fireAlert({
        ...this.mensagem_alert,
      });
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}</style>